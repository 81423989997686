<template>
  <section class="vision flex justify-center">
    <div class="vis flex column mx-md">
      <img src="../../assets/images/eye.svg" alt="" />
      <div class="vision-info pl-sm">
        <div class="vision-title bold text-primary">
          {{ $t("About.OurVision") }}
        </div>
        <div class="vision-description">
          {{ $t("About.OurVisionDescription") }}
        </div>
      </div>
    </div>
    <div class="vis flex column mx-md">
      <img src="../../assets/images/culture.svg" alt="" />
      <div class="vision-info pl-sm">
        <div class="vision-title bold text-primary">
          {{ $t("About.OurCulture") }}
        </div>
        <div class="vision-description">
          {{ $t("About.OurCultureDescription") }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss">
.vision {
  margin: 8vh 0;
  .vision-title {
    font-size: 22px;
  }
  .vision-description {
    font-size: 18px;
    color: #767676;
    line-height: 1.6rem;
  }
  .vis {
    width: 30%;
    img {
      height: 40px;
      width: 40px;
      object-fit: contain;
      object-position: center;
      margin-left: 10px;
    }
  }
}
@media (max-width: 1200px) {
  .vision {
    padding: 0 16px;
    flex-direction: column;
    .vis {
      margin-bottom: 40px;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 0;
      }
      .vision-info {
        justify-content: center;
        padding-left: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 50%;
      }
    }
  }
}
</style>
