<template>
  <section class="intro-images flex">
    <img
      class="mb-md"
      src="../../assets/images/aboutNepal.png"
      alt="aboutNepal"
    />
    <img
      class="mb-md"
      src="../../assets/images/aboutJapan.png"
      alt="aboutJapan"
    />
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss">
.intro-images {
  margin: 10vh 0;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/wavyBg.svg") center no-repeat;
  img {
    margin: 0 16px;
    height: 550px;
    width: 350px;
    object-fit: cover;
    object-position: top center;
  }
}
@media (max-width: 1200px) {
  .intro-images {
    margin: 4vh 0 0 0;
    padding: 0px 16px;
    flex-direction: row;
    justify-content: center;
    img {
      margin: 8px 0;
      width: 165px;
      height: 300px;
      object-fit: contain;
      object-position: top center;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
</style>
