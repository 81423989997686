<template>
  <main>
    <AboutCover />
    <IntroImages />
    <Vision />
    <GetInTouch />
    <Footer />
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import AboutCover from "@/components/About/AboutCover";
import IntroImages from "@/components/About/IntroImages";
//import Team from "@/components/About/Team";
import Vision from "@/components/About/Vision";
import GetInTouch from "@/components/Common/GetIntouch";
import Footer from "@/components/Common/Footer";
export default {
  name: "About",
  components: { Footer, GetInTouch, Vision, IntroImages, AboutCover }
};
</script>
