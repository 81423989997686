<template>
  <div class="banner contacts-cover flex-centered column text-light">
    <img src="../../assets/images/about-cover.jpg" alt="" class="banner-img" />
    <div
      class="header title relative half-width text-center pt-xl pb-md flex-centered"
    >
      <div class="light" v-if="$i18n.locale === 'en'">We are a&nbsp;</div>
      <span class="high-light bold" v-if="$i18n.locale === 'en'">
        Technology Start-Up
      </span>
      <div v-if="$i18n.locale === 'ja'" class="flex wrap justify-center">
        <div class="light">私たちは、&nbsp;</div>
        <div class="high-light bold">
          テクノロジー・スタートアップ
        </div>
        <div>企業です</div>
      </div>
    </div>
    <p class="sub-title relative text-center">
      {{ $t("About.TechStartUpDescription") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "ServicesCover"
};
</script>
<style scoped>
.banner {
  box-sizing: border-box;
  height: 400px;
  background-size: cover;
  position: relative;
  z-index: -1;
}
.header,
.sub-title {
  z-index: 1;
}
.title {
  font-size: 48px;
  font-family: "Poppins";
}
.sub-title {
  font-size: 22px;
  font-weight: 300;
  color: white;
}
.light {
  font-weight: 300;
}
@media (max-width: 1200px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  .sub-title {
    width: 80%;
    font-size: 18px;
  }
  .title {
    font-size: 24px;
  }
}
</style>
